<template>
  <div
    id="help-center-main-card-links"
    class="help-center-main-card-links"
    style="display: none"
  >
    <order-details
      v-if="showOD && userInfo?.isLoggedIn && !loading"
      :user-info="userInfo"
      :page-name="pageName"
      :check-order-length="(orders) => checkOrderLength(orders)"
      :show-previous-order-days="showPreviousOrderDays"
      :status-for-orders-not-shown="statusForOrdersNotShown"
      :max-no-of-orders="maxNoOfOrders"
    />
    <cdr-container
      tag="section"
      class="help-center__card-section"
    >
      <div class="help-center__card-links">
        <quick-tools-component
          v-if="showQT && !loading"
          :quick-tools="quickTools"
        />
        <div
          v-for="popularTopicsSection in popularTopicsSectionList"
          :key="popularTopicsSection.key"
          class="help-center__card-links-sales"
        >
          <cdr-text
            tag="h2"
            tabindex="0"
            class="help-center__heading-links"
          >
            {{ popularTopicsSection?.sectionHeading }}
          </cdr-text>
          <article
            class="help-center__card-links-container"
          >
            <div
              v-for="popularTopicsCard in popularTopicsSection?.cardModelList"
              :key="popularTopicsCard.key"
              class="help-center__card-links-card"
            >
              <cdr-text
                tag="h2"
                tabindex="0"
                class="help-center__card-title"
              >
                {{ popularTopicsCard.cardHeading }}
              </cdr-text>
              <cdr-link
                v-for="popularTopicsLink in popularTopicsCard.urlModelList"
                :key="popularTopicsLink.key"
                class="help-center__card-links-text"
                :href="popularTopicsLink.url"
                @click="linkHandler(popularTopicsSection?.key, popularTopicsLink.urlText)"
              >
                {{ popularTopicsLink.urlText }}
              </cdr-link>
            </div>
          </article>
        </div>
      </div>
    </cdr-container>
  </div>
</template>

<script>
import
{
  CdrContainer, CdrText, CdrLink,
} from '@rei/cedar';
import QuickToolsComponent from './QuickToolsComponent.vue';
import OrderDetails from './OrderDetails.vue';
import { camelCaseWithoutSC, snakeCase } from '../../common/utils';
import { sendClickAnalytics } from '../../utils';
import tags from '../../utils/globalTags';

export default {
  name: 'CardLinksComponent',
  components: {
    QuickToolsComponent,
    OrderDetails,
    CdrContainer,
    CdrText,
    CdrLink,
  },
  props: {
    popularTopicsSectionList: { type: Array, default() { return []; } },
    cardLinksConfig: { type: Array, default() { return []; } },
    saleLinksConfig: { type: Object, default() { return {}; } },
    statusForOrdersNotShown: { type: Array, default() { return []; } },
    showQuickTools: { type: Boolean, default: false },
    showOrderDetails: { type: Boolean, default: false },
    quickTools: { type: Array, default() { return []; } },
    userInfo: { type: Object, default() { return {}; } },
    showPreviousOrderDays: { type: Number, default: 30 },
    maxNoOfOrders: { type: Number, default: 0 },
  },
  data() {
    return {
      showQT: false,
      showOD: false,
      loading: false,
    };
  },
  watch: {
    userInfo() {
      this.loading = false;
      this.showQT = !this.userInfo?.isLoggedIn ? this.showQuickTools : false;
      this.showOD = !this.userInfo?.isLoggedIn ? false : this.showOrderDetails;
    },
  },
  methods: {
    checkOrderLength(orders) {
      this.showQT = orders && orders.length <= 0;
      this.showOD = (orders && orders.length > 0) ? this.showOrderDetails : false;
      this.loading = false;
    },
    quickLinksHandler(e) {
      let linkName = e.target.innerText;
      linkName = (linkName) ? linkName.replaceAll(' ', '-') : linkName;
      const clickData = {
        pageName: this.pageName,
        linkName: `${tags.HELP_CENTER_HOMEPAGE_POPULAR}_${linkName}`,
      };
      sendClickAnalytics(clickData);
    },
    linkHandler(sale, linkName) {
      this.analyticsHandler(`${tags.HELP_CENTER}_${snakeCase(sale)}_Help_${camelCaseWithoutSC(linkName)}`);
    },
    analyticsHandler(source) {
      const clickData = {
        pageName: this.pageName,
        linkName: source || tags.HELP_CENTER,
      };
      sendClickAnalytics(clickData);
    },
  },
};
</script>

<style scoped>

</style>

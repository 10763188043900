<script>
import {
  CdrText,
  CdrList,
  CdrLink,
} from '@rei/cedar';

import { sendClickAnalytics, sendViewAnalytics, getClientPageData } from '../../../utils';
import tags from '../../../utils/globalTags';

export default {
  name: 'ContactUsOld',
  components: {
    CdrText,
    CdrList,
    CdrLink,
  },
  props: {
    showHeadLink: { type: Boolean, default: true },
  },
  computed: {
    popularTools() {
      const popular = [
        { url: '/help/returns', tag: 'returns', label: 'Returns & Exchanges' },
        { url: '/OrderTrackingLoginView', tag: 'order_status', label: 'Order Status & Tracking' },
        { url: '/membership/lookup', tag: 'membership', label: 'Membership & Rewards Lookup' },
        { url: '/help/shipping-timelines.html', tag: 'shipping', label: 'Shipping Information' },
        { url: '/help?a=Cancel-My-Order---id--RunjZv_yTdO4Cewe5IZs9g', tag: 'modify_order', label: 'Cancel/Modify Order' },
        { url: '/help', tag: 'help_center', label: 'Help Center' },
      ];
      return popular;
    },
  },
  mounted() {
    const pageAnalyticsData = getClientPageData()?.analytics;
    sendViewAnalytics({ ...pageAnalyticsData, pageName: `${pageAnalyticsData.pageName}-contact-us_experience-A` });
  },
  methods: {
    popularToolsClickHandler(e) {
      e.preventDefault();
      const {
        target: {
          href = '',
          dataset: {
            tag = '',
          },
        },
      } = e;
      const clickData = {
        pageName: tags.HELP,
        linkName: `${tags.CONTACT_US_QUICK_HELP}_${tag}`,
      };
      sendClickAnalytics(clickData);
      window.location = href;
    },
    articleLinksHandler(e) {
      const {
        target: {
          href = '',
          dataset: {
            tag = '',
          },
        },
      } = e;
      const isPhone = href.includes('tel:');

      if (!isPhone) e.preventDefault();

      const clickData = {
        pageName: tags.HELP,
        linkName: `${tags.PAGE_CONTACT_US}_${tag}`,
      };
      sendClickAnalytics(clickData);
      if (!isPhone) window.location = href;
    },
    routerLinksHandler(e) {
      e.preventDefault();
      window.location = '/help';
    },
  },
};
</script>
<template>
  <div class="article">
    <cdr-link
      v-if="showHeadLink"
      class="article__heading-link"
      @click="routerLinksHandler"
    >
      Help Center Home
    </cdr-link>
    <cdr-text
      tag="h2"
      class="article__heading-old"
    >
      Contact Us
    </cdr-text>
    <cdr-text
      class="article__subheading"
    >
      Popular Tools for Quick Help
    </cdr-text>
    <br>
    <cdr-list modifier="unordered">
      <li
        v-for="tool in popularTools"
        :key="tool.url"
      >
        <cdr-link
          :href="tool.url"
          :data-tag="tool.tag"
          class="article__link"
          @click="popularToolsClickHandler"
        >
          {{ tool.label }}
        </cdr-link>
      </li>
    </cdr-list>
    <br>
    <cdr-text
      class="article__subheading article__subheading-after-list"
    >
      Email and Online Live Chat
    </cdr-text>
    <br>
    <cdr-text>
      REI representatives can provide email and live chat online for questions. Click on the green chat bubble in the lower-right corner of your screen for chat, or go to
      <cdr-link
        data-tag="email_us_page"
        href="/help/contact-us"
        @click="articleLinksHandler"
      >
        this page to send us an email
      </cdr-link>.
    </cdr-text>
    <br>
    <cdr-text>
      If your question is urgent or time-sensitive, we suggest contacting us via phone or live chat at your earliest convenience.
    </cdr-text>
    <cdr-text
      class="article__subheading"
    >
      Phone
    </cdr-text>
    <cdr-list>
      <li>
        From the U.S. and Canada—Call
        <cdr-link
          data-tag="US-phone"
          href="tel:+18004264840"
          @click="articleLinksHandler"
        >
          1-800-426-4840
        </cdr-link>
        Monday through Friday, 6 AM to 8 PM Pacific Time, Saturday and Sunday, 8 AM to 5 PM Pacific Time.
      </li>
      <li>
        International—Call
        <cdr-link
          data-tag="international-phone"
          href="tel:+12538912500"
          @click="articleLinksHandler"
        >
          1-253-891-2500
        </cdr-link>
        Monday through Friday, 6 AM to 8 PM Pacific Time, Saturday and Sunday, 8 AM to 5 PM Pacific Time. English language only.
      </li>
    </cdr-list>
    <ul />
    <br>
    <cdr-text
      class="article__subheading article__subheading-after-list"
    >
      Corporate & Group Sales
    </cdr-text>
    <br>
    <cdr-text>
      Get volume discounts when you outfit your team or group from our full line of outdoor gear and clothing. Ordering is easy. Learn more about our
      <cdr-link
        data-tag="cgs"
        href="/corporate-group-sales"
        @click="articleLinksHandler"
      >
        Corporate & Group Sales
      </cdr-link>.
    </cdr-text>
    <cdr-text
      class="article__subheading"
    >
      Retail Stores
    </cdr-text>
    <br>
    <cdr-text>
      Please see our
      <cdr-link
        data-tag="store-locator"
        href="/map/store"
        @click="articleLinksHandler"
      >
        Store Locator
      </cdr-link>
      for specific store information and hours for in-store shopping and Curbside Pickup.
    </cdr-text>
    <br>
  </div>
</template>
<style lang="scss">
  @import 'src/main/style/pages/internal/article.scss';
</style>

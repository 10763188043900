import '@rei/cedar/dist/reset.css';
import '@rei/cedar/dist/cdr-fonts.css';

/* page base */
import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

/* compo */
import '@rei/cedar/dist/style/cdr-text.css';
import '@rei/cedar/dist/style/cdr-card.css';
import '@rei/cedar/dist/style/cdr-link.css';
import '@rei/cedar/dist/style/cdr-accordion.css';
import '@rei/cedar/dist/style/cdr-accordion-group.css';
import '@rei/cedar/dist/style/cdr-icon.css';
import '@rei/cedar/dist/style/cdr-breadcrumb.css';
import '@rei/cedar/dist/style/cdr-button.css';
import '@rei/cedar/dist/style/cdr-banner.css';
import '@rei/spinner/dist/style.css';

import createApp from './main';
import { getClientPageData, initializeMetrics } from '../../utils';

const { pageData: props } = getClientPageData();
// Initialize the Metrics and Gladly objects.
const pageName = initializeMetrics();
props.pageName = pageName;

const { app } = createApp(props);
app.mount('#app');

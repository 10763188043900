<script>
import {
  CdrText,
} from '@rei/cedar';
import contactUs from './contactUs.vue';
import contactUsOld from './contactUsOld.vue';
import { getAdobeTargetJson } from '../../../common/utils';

export default {
  name: 'InternalPagesRouter',
  components: {
    CdrText,
    contactUs,
    contactUsOld,
  },
  props: {
    retailStoreHoursEvents: { type: Array, default() { return []; } },
    emailResponseTime: { type: Number, default: 24 },
    helpCenterNumbers: { type: Object, default() { return {}; } },
    popularTopicsLinks: { type: Array, default() { return []; } },
  },
  data() {
    return {
      isMatchedRoute: false,
      valueABTest: false,
    };
  },
  async created() {
    const adobeExperienceContactUsWidget = await getAdobeTargetJson(['help-popular-support-links']);
    this.isMatchedRoute = this.routeMatch('contact-us-landing');
    if (adobeExperienceContactUsWidget === 'B') {
      this.valueABTest = true;
    }
  },
  methods: {
    routeMatch(routeRef) {
      const routes = [
        { path: 'contact-rei', ref: 'contact-us-landing' },
      ];
      const path = this.checkForReturnPath();
      return routes.find((route) => route.path === path && routeRef === route.ref);
    },
    checkForReturnPath() {
      try {
        if (window && typeof window !== 'undefined') {
          const url = new URL(window.location.href);
          return url.searchParams.get('article');
        }
        return '';
      } catch (e) {
        return '';
      }
    },
  },
};
</script>
<template>
  <section
    class="article-container"
    tabindex="0"
  >
    <div
      v-if="!isMatchedRoute"
      class="article-container__heading"
    >
      <cdr-text
        tag="h1"
        class="article-container__heading-text"
      >
        REI Help Center
      </cdr-text>
      <hr>
    </div>
    <contact-us
      v-if="isMatchedRoute && valueABTest"
      :retail-store-hours-events="retailStoreHoursEvents"
      :email-response-time="emailResponseTime"
      :help-center-numbers="helpCenterNumbers"
      :popular-topics-links="popularTopicsLinks"
    />
    <contact-us-old v-if="isMatchedRoute && !valueABTest" />
  </section>
</template>
<style lang="scss">
  @import 'src/main/style/pages/internal/article.scss';
</style>
